<template>
    <div>
        <img src="../../../../assets/pc/images/about/contact2.png">
    </div>
</template>

<script>
export default {
  name: 'contact',
  data () {
    return {

    };
  }
};
</script>
