<template>
  <div class="about">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>关于我们</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="left">
        <div v-for="(item,index) in tabs" :key="index" class="left_item" @click="choose(index,item)" :class="item.active?'active':''">
          <div v-if="item.active"><img :src="item.choose"></div>
          <div v-else><img :src="item.notChoose"></div>
          <div>{{item.title}}</div>
        </div>
      </div>
      <div class="right">
        <enterprise v-show="step == 'enterprise'"></enterprise>
        <contact v-show="step == 'contact'"></contact>
        <problem v-show="step == 'problem'"></problem>
      </div>
    </div>
  </div>
</template>

<script>
import enterprise from './components/enterprise.vue';
import contact from './components/contact.vue';
import problem from './components/problem.vue';
export default {
  components: {
    enterprise,
    contact,
    problem
  },
  data () {
    return {
      tabs: [
        {
          name: 'enterprise',
          title: '企业概况',
          choose: require('../../../assets/pc/images/about/one.png'),
          notChoose: require('../../../assets/pc/images/about/one_un.png'),
          active: true
        },
        {
          name: 'contact',
          title: '联系我们',
          choose: require('../../../assets/pc/images/about/three.png'),
          notChoose: require('../../../assets/pc/images/about/three_un.png'),
          active: false
        },
        {
          name: 'problem',
          title: '常见问题',
          choose: require('../../../assets/pc/images/about/four.png'),
          notChoose: require('../../../assets/pc/images/about/four_un.png'),
          active: false
        }
      ],
      select: ''
    };
  },
  computed: {
    step () {
      return this.$store.state.about.step;
    }
  },
  watch: {
    '$store.state.about.step': {
      handler (val) {
        this.tabs.forEach((v, i) => {
          v.name === val ? v.active = true : v.active = false;
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    choose (index, item) {
      this.tabs.forEach((v, i) => {
        i === index ? v.active = true : v.active = false;
      });
      this.$store.commit('about/setStep', item.name);
    }
  }
};
</script>

<style lang="less" scoped>
  .about{
    background-color: #fff;
    padding: 45px 55px;
    .content{
      margin-top: 40px;
      display: flex;
      .left{
        .left_item{
          padding: 17px 60px 17px 18px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          display: flex;
          align-items: center;
          >div:nth-child(1){
            margin-right: 18px;
          }
        }
      }
      .right{
        flex: 1;
        margin-left: 45px;
      }
    }
  }
  .active{
    background: #EBF3FF;
    color: #156ED0;
  }
</style>
