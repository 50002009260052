<template>
    <div>
        <img src="../../../../assets/pc/images/about/enterprise2023.png">
    </div>
</template>

<script>
export default {
  name: 'enterprise',
  data () {
    return {

    };
  }
};
</script>
